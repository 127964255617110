// BaseModule.js
import axios from 'axios'
import qs from 'query-string'
import router from '@/router'
import store from '@/store'
import { baseconfig } from "@/config.js";
import { Toast } from 'vant';
let ShowMsg = null
class BaseModule {
  constructor() {
    this.$http = axios.create({
      baseURL: process.env.VUE_APP_API_BASE_URL,
      timeout: 200000
    })
    this.baseURL = process.env.VUE_APP_API_BASE_URL;
    this.access_token = () => store.getters.token;
    this.qs = qs;
    this.$http.interceptors.request.use(function (config) {   // 设置请求头
      config.headers.common['X-EN-WX-USER-ID'] = store.getters.userId
      config.headers.common['X-EN-WX-TOKEN'] = store.getters.token
      console.log("%c%s", "color: white; background: green; font-size: 14px;", '请求参数:', config, config.url, config.data || config.params)
      return config;
    }, function (error) {
      // 对请求错误做些什么
      return Promise.reject(error);
    });
    this.$http.interceptors.response.use(function (response) {
      console.log("%c%s", "color: red; background: yellow; font-size: 14px;", '请求结果:', response.config.url, response.data)
      // 对响应数据做点什么
      if (response.config.notShowMsg) return response.data;
      switch (response.data.code) {
        case '200':
          break;
        case 'enwx0000005':
          // Toast('用户信息校验失败，重新授权登录');
          store.dispatch('admin/removeToken').then(() => {
            location.reload()
          })
          break;
        default:
          Toast.fail(response.data.message || baseconfig.net_error_message);
          break;
      }
      return response.data;
    }, function (error) {
      try {
        switch (error.response.status) {
          case 401:
            if (ShowMsg) return;
            Toast.fail('登录失效');
            break;
          default:
            Toast.fail(error.response.data.message || baseconfig.net_error_message);
            break;
        }
      } catch (err) {
        console.error(err, '监听到错误')
        Toast.fail(baseconfig.net_error_message);
      }

    });
    this.dataMethodDefaults = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      transformRequest: [function (data) {
        return qs.stringify(data)
      }]
    }
  }

  get (url, params = undefined, config = {}) {
    return this.$http.get(url, { params }, config)
  }
  post (url, data = undefined, config = {}) {
    return this.$http.post(url, data, { ...this.dataMethodDefaults, ...config })
  }
  postJson (url, data = undefined, config = {}) {
    return this.$http.post(url, data, config)
  }
  upload (url, data = undefined, config = {}) {
    let formData = new FormData();
    if (data) {
      if (Array.isArray(data.files)) {
        data.files.forEach(i => {
          formData.append('files', i)
        });
        delete data.files;
        for (let key in data) {
          formData.append(key, data[key])
        }
      } else {
        for (let key in data) {
          formData.append(key, data[key])
        }
      }
    }
    return this.$http.post(url, formData, { ...config })
  }
  put (url, data = undefined, config = {}) {
    return this.$http.put(url, data, { ...this.dataMethodDefaults, ...config })
  }
  delete (url, config = {}) {
    return this.$http.delete(url, config)
  }
}

export default BaseModule