import VueRouter from 'vue-router'
import store from '@/store'

const notfond = () => import( /* webpackChunkName: "main" */ '../components/page/otherPage/404.vue');   //404页面

const main = () => import( /* webpackChunkName: "main" */ '../components/common/layout/Layout.vue');
const home = () => import( /* webpackChunkName: "home" */ '../components/page/homePage/Home.vue');   //首页
const Interview = () => import( /* webpackChunkName: "Interview" */ '../components/page/pageCon/Interview.vue');   //面诊档案
const detection = () => import( /* webpackChunkName: "detection" */ '../components/page/pageCon/detection.vue');   //检测档案
const SkinTesting = () => import( /* webpackChunkName: "SkinTesting" */ '../components/page/pageCon/SkinTesting.vue');   //检测档案
const report = () => import( /* webpackChunkName: "report" */ '../components/page/pageCon/report.vue');   //检测档案
const orderInfo = () => import( /* webpackChunkName: "orderInfo" */ '../components/page/pageCon/orderInfo.vue');   //检测档案
const storeRecord = () => import( /* webpackChunkName: "storeRecord" */ '../components/page/pageCon/storeRecord.vue');   //检测档案
const activity = () => import( /* webpackChunkName: "storeRecord" */ '../components/page/pageCon/activity.vue');   //检测档案

const chiefComplaint = () => import( /* webpackChunkName: "chiefComplaint  " */ '../components/page/pageCon/chiefComplaint.vue');   //检测档案

const userTestReport = () => import( /* webpackChunkName: "report" */ '../components/page/userTestPage/TestReportPage.vue');   //用户检测图
const contrastReport = () => import( /* webpackChunkName: "report" */ '../components/page/userTestPage/ContrastTestReport.vue');   //检测图-对比结果

const comprehensive = () => import( /* webpackChunkName: "report" */ '../components/page/pageCon/comprehensiveCon.vue');   //检测图-对比结果


const privateFileAdd = () => import( /* webpackChunkName: "report" */ '../components/page/pageCon/privateFileAdd.vue');   //追加-私密档案
const trackTask = () => import( /* webpackChunkName: "report" */ '../components/page/customFiles/trackTask/index.vue');   //客户跟踪任务
const treatmentPlan = () => import( /* webpackChunkName: "report" */ '../components/page/customFiles/treatmentPlan/index.vue');   //客户治疗方案

var router = new VueRouter({
  // mode: 'history',
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/', component: main, redirect: '/home', meta: { title: '首页' },
      children: [
        { path: '/home', component: home, meta: { title: '首页' } },
        { path: '/orderInfo', component: orderInfo, meta: { title: '用户订单' } },
        { path: '/storeRecord', component: storeRecord, meta: { title: '入店记录' } },
        { path: '/activity', component: activity, meta: { title: '活动' } },
        { path: '/trackTask', component: trackTask, meta: { title: '客户跟踪任务' } },
      ]
    },
    { path: '/Interview', component: Interview, meta: { title: '面诊档案' } },
    { path: '/detection', component: detection, meta: { title: '检测档案' } },
    { path: '/SkinTesting', component: SkinTesting, meta: { title: '测肤小程序' } },
    { path: '/report', component: report, meta: { title: '测肤报告' } },
    { path: '/chiefComplaint', component: chiefComplaint, meta: { title: '历史主诉列表' } },
    { path: '/report/userTestReport', component: userTestReport, meta: { title: '检测图' } },
    { path: '/report/contrastReport', component: contrastReport, meta: { title: '检测图' } },
    { path: '/comprehensive', component: comprehensive, meta: { title: '综合皮肤档案' } },
    { path: '/privateFileAdd', component: privateFileAdd, meta: { title: '追加私密档案' } },
    { path: '/treatmentPlan', component: treatmentPlan, meta: { title: '客户治疗方案' } },

    { path: '/404', component: notfond, meta: { title: '页面不存在' } },
    { path: '*', redirect: '/404', }
  ],
  scrollBehavior (to, from, saveTop) {   //路由切换页面回到顶部展示
    // console.log('scrollBehavior',to, from, saveTop)
    return { x: 0, y: 0 }
  }
})

// router.beforeEach(async (to, from, next) => {
//   if (to.meta.title) document.title = to.meta.title;
//   next()
// })

// 解决vue-router在3.0版本以上重复点报错问题(多次跳转同一个页面)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router