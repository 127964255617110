export const util = {
  /**
   * @function 十以内数字补0
   * @param {number} n 十以内数字
   * @returns
   *  十以内数字补0
   */
  formatNum (n) {
    n = n.toString();
    return n[1] ? n : "0" + n;
  },
  /**
   * @function 格式化时间
   * @param {*} dateTime  时间参数
   * @param { string }  fmt  返回格式
   * @return {string}  格式化后的字符串
   * @example
   * formatTime(1625801943114,'yyyy年mm月dd日');
   */
  formatTime (dateTime = 0, fmt = 'yyyy-mm-dd') {
    if (dateTime === null || dateTime === 0) return '-';
    if (typeof str === 'string' && dateTime) dateTime = new Date(dateTime).getTime();
    if (!dateTime) dateTime = Number(new Date());  // 如果为null,则格式化当前时间
    if (dateTime.toString().length == 10) dateTime *= 1000;   // 如果dateTime长度为10或者13，则为秒和毫秒的时间戳，如果超过13位，则为其他的时间格式
    let date = new Date(dateTime);
    let ret;
    let opt = {
      "y+": date.getFullYear().toString(), // 年
      "m+": (date.getMonth() + 1).toString(), // 月
      "d+": date.getDate().toString(), // 日
      "h+": date.getHours().toString(), // 时
      "M+": date.getMinutes().toString(), // 分
      "s+": date.getSeconds().toString() // 秒
      // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
      ret = new RegExp("(" + k + ")").exec(fmt);
      if (ret) {
        fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
      }
    }
    return fmt;
  },
  /**
   * @function 格式化时间差
   * @param {number} time  秒
   * @param { string } fmt  返回格式
   * @return {string}  格式化后的字符串
   */
  formatTimeDiff (time = null, fmt = 'hh:MM:ss') {
    let ret;
    let opt = {
      "d+": parseInt(time / (60 * 60 * 24)).toString(), // 日
      "h+": parseInt(time % (60 * 60 * 24) / 3600).toString(), // 时
      "M+": parseInt(time % (60 * 60 * 24) % 3600 / 60).toString(), // 分
      "s+": parseInt(time % (60 * 60 * 24) % 3600 % 60).toString() // 秒
      // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
      ret = new RegExp("(" + k + ")").exec(fmt);
      if (ret) {
        fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
      }
    }
    return fmt;
  },
  /**
   * 将驼峰转为下划线
   * @param {*} obj 
   * @returns 处理后数据
   */
  camelToUnderline (obj) {
    let newObj = {}
    Object.entries(obj).forEach(([key, value]) => {
      newObj[key.replace(/([A-Z])/g, "_$1").toLowerCase().replace(/_i_d/g, '_id')] = value
    })
    return newObj
  },
  /**
   * 处理select框 lable值为空
   * @param {*} str_value 
   * @returns 处理后数据
   */
  toSelectLableStr (str_value) {
    let str_data_value = (str_value ? encodeURI(str_value) : '--').replace(/&nbsp;/g, '%20');
    return decodeURI(str_data_value);
  },

  /**
   * 保留两位小数
   * @param {*} value  值
   * @param {*} point  几位小数
   * @returns  结果
   */
  fmtToFix (value = 0, point = 2) {
    return Number(value).toFixed(point)
  },

  /**
   * 处理价格---整数、小数分开
   * @param {*} value 
   * @param {*} isSpot 
   * @returns 
   */
  fmtConvertPrice (value = 0, isSpot = true) {
    if (typeof value !== 'string' && typeof value !== 'number') throw new TypeError('Value must be a string or number.'); // 数据类型校验不足：确保value可以被转换为数字
    value = Number(value).toFixed(2).toString();  // 将value转换为数字字符串，并确保其最多保留两位小数
    let price = {};  // 使用let/const代替var进行变量声明
    if (isSpot) {
      let pointsPointsNum = value.indexOf('.');
      if (pointsPointsNum === -1) {  // 如果value不包含小数点，手动添加以确保代码后续部分可以正常运行
        value += '.00';
        pointsPointsNum = value.indexOf('.');
      }
      price.price_int = value.substring(0, pointsPointsNum); // 商品价钱的整数部分
      price.price_decimal = value.substring(pointsPointsNum); // 商品价钱的小数部分
    } else {
      price.price_int = parseInt(value, 10);
      let decimalPart = value.split('.')[1];  // 处理非标准数字字符串时的潜在错误：确保value.split('.')[1]不为undefined
      price.price_decimal = decimalPart ? decimalPart : '00';
    }
    return price;
  },
  /**
   * 处理金额
   */
  toThousands (value) {
    if (typeof value !== 'number') throw new TypeError('Expected input to be a number'); // 类型检查，确保是数字类型
    const valueStr = value.toString();  // 将数字转换为字符串，准备处理
    const values = valueStr.split('.');  // 分离整数和小数部分
    let integerNum = values[0];  // 处理整数部分
    // 处理小数部分
    let decimalNum = values[1] ? values[1] : '';  // 默认为空字符串，避免硬编码'00'
    decimalNum = decimalNum.padEnd(2, '0'); // 确保小数部分至少两位，不足则补0
    if (integerNum < 1000) return `${integerNum}.${decimalNum}`;  // 传入值小于1000不切割
    const list = [];
    while (integerNum.length > 3) {  // 倒序切割整数部分
      list.unshift(integerNum.slice(-3));
      integerNum = integerNum.slice(0, -3);
    }
    list.unshift(integerNum);  // 加入剩余的整数部分
    const result = list.join(',');  // 拼接最终结果，使用千分位分隔符
    return `${result}.${decimalNum}`;   // 返回格式化后的字符串
  },
  /**
   * 计算时间差
   * @param {*开始时间} startDate 
   * @param {*结束时间} endDate 
   */
  diffTime (startDate, endDate) {
    if (!(startDate instanceof Date) || !(endDate instanceof Date)) throw new Error('startDate和endDate必须是Date对象'); // 确保startDate和endDate是有效的Date对象
    const diff = endDate.getTime() - startDate.getTime();  // 使用getTime确保以毫秒为单位进行比较
    const days = Math.floor(diff / (24 * 3600 * 1000));  // 计算相差天数
    const hours = Math.floor((diff % (24 * 3600 * 1000)) / (3600 * 1000));  // 计算小时数
    const minutes = Math.floor((diff % (3600 * 1000)) / (60 * 1000));  // 计算相差分钟数
    const seconds = Math.round((diff % (60 * 1000)) / 1000);  // 计算相差秒数
    // 构造返回的字符串
    let returnStr = seconds + "秒前";
    if (minutes > 0) returnStr = minutes + "分钟前";
    if (hours > 0) returnStr = hours + "小时前";
    if (days > 0) returnStr = days + "天前";

    return returnStr;
  },

  /**
   * @function 函数节流
   * @param {Function} fn 传入函数
   * @param {number} interval 时间间隔，不传值默认为500ms
   * @returns {Function}
   * @example
   * handleClickDate2: util.throttle(function (e) {
     console.log('函数节流')
  }, 500)
  */
  throttle (fn, interval) {
    var enterTime = 0; //触发的时间
    var gapTime = interval || 500; //间隔时间，如果interval不传值，默认为300ms
    return function () {
      var that = this;
      var backTime = new Date(); //第一次函数return即触发的时间
      if (backTime - enterTime > gapTime) {
        fn.call(that, ...arguments);
        enterTime = backTime; //赋值给第一次触发的时间 保存第二次触发时间
      }
    };
  },


}