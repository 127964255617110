import router from './index.js'
import { baseconfig } from "@/config.js";
import reportManager from '@/request/reportManager.js'
import store from '../store'
const whiteList = ['/404']
// 路由守卫
router.beforeEach(async (to, from, next) => {
  if (to.meta.title) document.title = to.meta.title;
  // 在白名单，直接进入
  if (whiteList.indexOf(to.path) !== -1) return next();
  if (!/wxwork/i.test(navigator.userAgent)) {  //不是企业微信环境
    // toWeixinAuthorize();  //跳转企微授权
    switch (store.getters.loginStatus) {
      case 0:
        console.log('未授权')
        store.dispatch('admin/setLoginStatus', 1);
        location.reload();
        break;
      case 1:
        console.log('已授权')
        store.dispatch('admin/setToken', 'GDvLFXHCBheqJFi3CSrSptVnVeJftitd4J7KjqhwzM94kLMO9C8V0Cj8k07mVLrrBY60vKVeTvo5l2Rd4OumqKeDEgGgeKHO3Nqh');
        store.dispatch('admin/setUserId', 'YangXianFeng');
        store.dispatch('admin/setLoginStatus', 2);
        next({ path: to.fullPath, replace: true });
        break;
      case 2:
        console.log('已登录')
        next();
        break;
      default:
        break;
    }
  } else {
    switch (Number(store.getters.loginStatus)) {
      case 0:
        console.log('企微-未授权')
        toWeixinAuthorize();  //跳转企微授权
        break;
      case 1:
        var wxCode = getUrlKey("code");
        console.log('企微-已授权', store.getters.loginStatus, window.location.href, `code值：${wxCode}`)
        if (wxCode) {
          sessionStorage.setItem('wxRedirectUrl', to.fullPath)   //保存当前路由地址，授权后还会跳到此地址
          getUserTokenByCodeData(wxCode, next);
        } else {
          store.dispatch('admin/removeLoginStatus')  //清空登录状态
          toWeixinAuthorize();  //跳转企微授权
        }
        break;
      case 2:
        console.log('企微-已登录')
        next();
        break;
      default:
        break;
    }
  }
  // next()
})
function toWeixinAuthorize () {
  var href = window.location.href;
  var relUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${baseconfig.CORPID}&redirect_uri=${encodeURIComponent(href)}&response_type=code&scope=snsapi_base&state=STATE&agentid=${baseconfig.AGENTID}#wechat_redirect`;
  window.location.href = relUrl;
  store.dispatch('admin/setLoginStatus', 1)
}
function getUrlKey (name) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
  let url = window.location.href.split('#')[0]
  let search = url.split('?')[1]
  if (search) {
    var r = search.substr(0).match(reg)
    if (r !== null) return unescape(r[2]);
    return null;
  } else {
    return null;
  }
}
// 通过code获取用户token信息
async function getUserTokenByCodeData (wxCode, next) {
  let redirectUrl = sessionStorage.getItem("wxRedirectUrl");
  try {
    let { code, data, message } = await reportManager.getUserTokenByCode({ code: wxCode })
    if (code == 200 && data) {
      store.dispatch('admin/setToken', data.token);
      store.dispatch('admin/setUserId', data.userId);
      store.dispatch('admin/setLoginStatus', 2)
      next({ path: redirectUrl, replace: true });
    }
  } catch (error) {
    console.error(error)
  }
}
