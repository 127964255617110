import BaseModule from './utils/BaseModule'
import store from '@/store'
import { baseconfig } from "@/config.js";
class BaseManager extends BaseModule {
  constructor() {
    super()
  }

  // 查看检测报告
  getSkinTestReport (param) {
    return this.get('/customer/customerTerminalScanningResult/getByScanningResultRecordId', param)
  }

  // 用户的所有有检测报告的入店记录---时间
  getRecordTimeData (param) {
    return this.get('/customer/customerTerminalScanningResult/getAllRecordFromCompare', param)
  }

  // 通过code获取用户token信息
  getUserTokenByCode (param) {
    return this.get('/enwx/getUserTokenByCode', param)
  }

}

export default new BaseManager()

