import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './router/permission.js'
import VueRouter from 'vue-router'
import store from './store'
import Vant from 'vant';
import ElementUI from 'element-ui';
import VConsole from 'vconsole'    //打印

Vue.config.productionTip = false
import 'amfe-flexible'; //适配移动端
import 'vant/lib/index.css';
import 'nprogress/nprogress.css'   //全局加载条样式
import "./assets/css/text-color.css"; /* 字体颜色、样式 */
import "./assets/css/minireseter.css"; /* 初始化 */
import "./assets/css/flex.css"; /* flex布局 */
import "./assets/css/systheme.css"; /* 系统样式 */
import './element-variables.scss';   //element自定义样式

import { util } from "@/tools/util.js";
Vue.prototype.$util = util;
import { encrypt, decrypt } from '@/tools/cryptoUtil.js';
Vue.prototype.$encrypt = encrypt;
Vue.prototype.$decrypt = decrypt;
import bus from './components/common/bus.js' //这是我的路径，正确引用你们的路径
Vue.prototype.$bus = bus


Vue.use(VueRouter)
Vue.use(Vant);
Vue.use(ElementUI);
if (process.env.NODE_ENV === 'preview' || process.env.NODE_ENV === 'production') {
  const vConsole = new VConsole()
  Vue.use(vConsole);  //打印
}


new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')


// 定义防止滚动的函数，提高代码复用性
const preventScroll = function (e) { e.preventDefault(); };
// 使用Vue的原型方法来控制滚动，但改进了内存管理和防止重复绑定
Vue.prototype.stopScroll = function () {
  if (!document.__scrollLocked) {   // 检查是否已经绑定了事件监听器，如果没有则添加
    document.body.style.overflow = 'hidden'; // 隐藏滚动条
    document.addEventListener('touchmove', preventScroll, { passive: false });
    document.__scrollLocked = true; // 设置标志表示监听器已绑定
  }
};

Vue.prototype.canScroll = function () {
  if (document.__scrollLocked) {  // 如果监听器已绑定，则移除
    document.body.style.overflow = ''; // 出现滚动条
    document.removeEventListener('touchmove', preventScroll, { passive: false });
    document.__scrollLocked = false; // 清除标志表示监听器已移除
  }
};
