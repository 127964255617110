const state = {
  loginStatus: localStorage.getItem('loginStatus') ? localStorage.getItem('loginStatus') : 0, // 登录态 0未授权未登录 1授权未登录 2 登陆成功
  token: localStorage.getItem('token') ? localStorage.getItem('token') : '',    //token //gKKWTTTB97rQsICGgEbKdAFdQlA2dIaIu8tqkxBEVNW4fplwHQaVovmPCtB4wHMnIs2YHKuEBo76uG2Sod4odcBu3bR2x6P5obeS //'GDvLFXHCBheqJFi3CSrSptVnVeJftitd4J7KjqhwzM94kLMO9C8V0Cj8k07mVLrrBY60vKVeTvo5l2Rd4OumqKeDEgGgeKHO3Nqh'
  userId: localStorage.getItem('userId') ? localStorage.getItem('userId') : '',  //用户ID MuNan //'YangXianFeng'
  custId: 13,   //客户id
}

const mutations = {
  SET_LOGIN_STATUS: (state, login_Status) => {    //设置 登录状态
    localStorage.setItem('loginStatus', login_Status)
    state.loginStatus = login_Status
  },
  REMOVE_LOGIN_STATUS: (state) => {   //移除 登录状态
    localStorage.removeItem('loginStatus')
    state.loginStatus = 0
  },
  SET_TOKEN: (state, token) => {    //设置token
    localStorage.setItem('token', token)
    state.token = token
  },
  REMOVE_TOKEN: (state) => {   //移除token
    localStorage.removeItem('token')
    state.token = ''
  },
  SET_USERID: (state, user_id) => {    //设置userId
    localStorage.setItem('userId', user_id)
    state.userId = user_id
  },
  REMOVE_USERID: (state) => {   //移除userId
    localStorage.removeItem('userId')
    state.userId = ''
  },
  SET_CUSTID: (state, cust_id) => {    //设置客户id
    localStorage.setItem('custId', cust_id)
    state.custId = cust_id
  },
  REMOVE_CUSTID: (state) => {   //移除客户id
    localStorage.removeItem('custId')
    state.custId = ''
  },
}
const actions = {
  setLoginStatus ({ commit }, login_Status) {   //设置 登录状态
    return new Promise(resolve => {
      commit('SET_LOGIN_STATUS', login_Status)
      resolve()
    })
  },
  removeLoginStatus ({ commit }) {    //移除 登录状态
    return new Promise(resolve => {
      commit('REMOVE_LOGIN_STATUS')
      resolve()
    })
  },
  setToken ({ commit }, token) {   //设置token
    return new Promise(resolve => {
      commit('SET_TOKEN', token)
      resolve()
    })
  },
  removeToken ({ commit }) {    //移除token
    return new Promise(resolve => {
      commit('REMOVE_TOKEN')
      commit('REMOVE_LOGIN_STATUS')
      commit('REMOVE_CUSTID')
      resolve()
    })
  },
  setUserId ({ commit }, user_id) {  //设置 用户ID
    return new Promise(resolve => {
      commit('SET_USERID', user_id)
      resolve()
    })
  },
  removeUserId ({ commit }) { //移除 用户ID
    return new Promise(resolve => {
      commit('REMOVE_USERID')
      resolve()
    })
  },
  setCustId ({ commit }, cust_id) {  //设置 客户id
    return new Promise(resolve => {
      commit('SET_CUSTID', cust_id)
      resolve()
    })
  },
  removeCustId ({ commit }) { //移除 客户id
    return new Promise(resolve => {
      commit('REMOVE_CUSTID')
      resolve()
    })
  },


}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}