<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  data () {
    return {
      
    }
  },
  methods: {},
  watch: {},
}
</script>

<style>

#nprogress .bar {
  background: #e2040e !important;
}

/* 特殊日子--- 置灰 */
html.gray {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray;
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
}

/* .search-content-position{
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.search-content-position img{
  opacity: 0.05;
  width: 600px;
  height: 623px;
} */

</style>
